import { object } from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import * as styles from './lawyer-profile.module.scss';
import { Wrap } from '../sections-wrap';
import phone from '../../assets/icons/lawyer-profile-phone.svg';
import schedule from '../../assets/icons/lawyer-profile-schedule.svg';
import { ZoomCalendar } from '../zoom-calendar';

function Lawyer({ data }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      {open && <ZoomCalendar onClose={() => setOpen(false)} /> }
      <div className={styles.pageContainer}>
        <Wrap>
          <main className={styles.main}>
            <article className={styles.entry}>
              <div>
                <div className={styles.photo}>
                  <GatsbyImage
                    image={getImage(data?.featuredImage?.node?.gatsbyImage)}
                    alt={data?.featuredImage?.node?.altText}
                  />
                </div>
                <div
                  className={styles.details}
                  dangerouslySetInnerHTML={
                    { __html: data?.attorneyTeamSingleConfiguration?.eaTeamDetails }
                  }
                />
              </div>
              <div>
                <div className={styles.contact}>
                  <header className={styles.header}>
                    <h1 className={styles.title}>{data.title}</h1>
                    <span className={styles.speciality}>
                      {data?.attorneyTeamSingleConfiguration?.eaTeamSpecialty}
                    </span>
                  </header>
                  <p className={styles.section}>
                    <img src={phone} alt="phone icon" />
                    Call:
                    {' '}
                    <a href="tel:303-338-2365">
                      {data?.attorneyTeamSingleConfiguration?.eaTeamPhone}
                    </a>
                  </p>
                  {' '}
                  <button
                    onClick={() => setOpen(true)}
                    type="button"
                    className={styles.info}
                  >
                    <img className={styles.icon} src={schedule} alt="schedule" />
                    <div>
                      Book Now
                    </div>
                  </button>
                  {data?.attorneyTeamSingleConfiguration?.eaTeamQuote
                    && (
                      <p
                        className={styles.quote}
                        dangerouslySetInnerHTML={
                          { __html: data?.attorneyTeamSingleConfiguration.eaTeamQuote }
                        }
                      />
                    )}
                </div>
                <div className={styles.bio} dangerouslySetInnerHTML={{ __html: data?.content }} />
              </div>
            </article>
          </main>
        </Wrap>
      </div>
    </>
  );
}

Lawyer.propTypes = {
  data: object,
};

Lawyer.defaultProps = {
  data: {},
};

export { Lawyer };
