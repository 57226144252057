import { graphql } from 'gatsby';
import { object } from 'prop-types';
import { Lawyer } from '../components/lawyer-profile';

function TeamMemberTemplate({ data }) {
  return (
    <Lawyer data={data?.wpPage} />
  );
}

export const query = graphql`
  query wpTeamMemberTemplate($uri: String) {
    wpPage: wpTeamMember(uri: {eq: $uri}) {
      ...SeoTeamMember
      title
      uri
      content
      featuredImage {
        node {
          altText
          gatsbyImage(width:400)
        }
      }
      attorneyTeamSingleConfiguration {
        eaTeamDetails
        eaTeamPhone
        eaTeamQuote
        eaTeamSpecialty
      }
    }
  }
`;

TeamMemberTemplate.propTypes = {
  data: object,
};

TeamMemberTemplate.defaultProps = {
  data: {},
};

export default TeamMemberTemplate;
export { Head } from '../components/seo/seo';
