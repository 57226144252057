// extracted by mini-css-extract-plugin
export var bio = "lawyer-profile-module--bio--d72f3";
export var contact = "lawyer-profile-module--contact--c21f8";
export var details = "lawyer-profile-module--details--cf420";
export var entry = "lawyer-profile-module--entry--4058a";
export var header = "lawyer-profile-module--header--d1ed0";
export var icon = "lawyer-profile-module--icon--d7ae8";
export var info = "lawyer-profile-module--info--81def";
export var main = "lawyer-profile-module--main--e7fcb";
export var pageContainer = "lawyer-profile-module--page-container--3f173";
export var photo = "lawyer-profile-module--photo--2bf1d";
export var popup = "lawyer-profile-module--popup--82606";
export var quote = "lawyer-profile-module--quote--288c6";
export var section = "lawyer-profile-module--section--72725";
export var speciality = "lawyer-profile-module--speciality--1defb";
export var title = "lawyer-profile-module--title--df6a2";